import { NgModule } from '@angular/core';

import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [],
  imports: [ComponentsModule],
  exports: [],
})
export class SharedModule {}
